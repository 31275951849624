import {Component, Input} from '@angular/core';

import { AuthenticationService } from '../../services';
import { User } from '../../../models';

@Component({
  selector: 'pages-top',
  templateUrl: './pages-top.component.html',
  styleUrls: ['./pages-top.component.scss'],
})
export class PagesTopComponent {
  avatarImgSrc: string = 'assets/images/avatar.png';

  currentUser: User;
  isAdmin: boolean;

  dropdownMenuUser = false;

  // sidebarToggle: boolean = true;
  // tip = {ring: true, email: true};

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.parseUserData();
  }

  parseUserData() {
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.currentUser);
    if (this.currentUser && (this.currentUser.user.role['name'] === "admin")) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  showDropdown(dropdownId) {
    this[dropdownId] = !this[dropdownId];
  }

  onLogOut() {
    this.authenticationService.logout();
    location.reload();
  }
}
