import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { TagsService, AuthenticationService } from '../../shared/services';


@Component({
  selector: 'app-virify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})

export class VerifyComponent implements OnInit {
  loading = false;
  submitted = false;
  error = '';

  returnUrl: string;

  currentRouteId: string;

  isVerified: boolean;

  verifyEmail: string;


  constructor(
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    console.log(this.route.params["value"].id);
    this.currentRouteId = this.route.params["value"].id;
    
    if (this.currentRouteId) {
      this.authenticationService.verify(this.currentRouteId)
          .pipe(first())
          .subscribe(
              data => {
                console.log(data);
                // this.returnUrl = 'admin-panel/tags';
                this.isVerified = true;
              },
              error => {
                  console.log(error);
                  this.error = error;
                  this.isVerified = false;
                  alert(error.msg);
              });
      
    }
    // for resend verification:
    this.verifyEmail = localStorage.getItem("verifyEmail");

  }

  
  onResend() {
    this.submitted = true;
    this.loading = true;

    this.authenticationService.resendVerify(this.verifyEmail)
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              alert("Check your email to verify account, please.");
            },
            error => {
                console.log(error);
                this.error = error;
                this.loading = false;
            });
  }

 

}
