import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

// import { Data } from '../../../models';

@Component({
  selector: 'app-search-in-table',
  templateUrl: './search-in-table.component.html',
  styleUrls: ['./search-in-table.component.scss'],

})
export class SearchInTableComponent implements OnInit, OnChanges {
  
  public value: any;
  // private valueUpd: string;

  @Input() tableDataDefault: Array<any>;
  @Input() valueFromParent:boolean;
  @Input() searchKeys: Array<string>;
  @Input() searchKeysSecondLevel: Array<string>;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.valueFromParent == true)
      this.value = "";
  }

  onSearch() {
    let tableDataFiltered: Array<any>;
    tableDataFiltered = this.tableDataDefault.filter((item) => {
      for (let key in item) {
        if (this.searchKeys.includes(key)) {

          if ((typeof item[key] !== "object") && String(item[key]).toLowerCase().includes(String(this.value).toLowerCase())) {
            // console.log(`String: ${key}, ${item[key]}`);
            return String(item[key]).toLowerCase().includes(String(this.value).toLowerCase());

          }
          
          /**** for arrays and objects: ****/
          else if ((typeof item[key] === "object") && item[key] && item[key].length) {
            // console.log(`Array: ${key}, ${item[key]}`);
            let itemFitered = item[key].filter((item2) => {
              for (let i in item2) {
                let itemSecondLevel = item2[i];
                return String(itemSecondLevel).toLowerCase().includes(String(this.value).toLowerCase());
              }
            })
            return itemFitered.length > 0;
          }

          // else {
          //   console.log(`Object: ${key}, ${item[key]}`);
          //   for (let prop in item[key]) {
          //     return item[key][prop].toLowerCase().includes(String(this.value).toLowerCase());
          //   }
          // }
            
          
        }
      }
      
    });

    this.notify.emit(tableDataFiltered);
  }

}
