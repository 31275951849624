import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Hosts, Tag } from '../../models';

@Injectable({ providedIn: 'root' })
export class TagsService {
    constructor(private http: HttpClient) { }

    getAllTags() {
        return this.http.get<Tag[]>(`${Hosts.API_HOST}/tag`);
    }
    getUserTags() {
        return this.http.get<Tag[]>(`${Hosts.API_HOST}/tag/userTags`);
    }
    getTag(id: number) {
        return this.http.get<any>(`${Hosts.API_HOST}/tag/get/${id}`)
        .pipe(map(response => {
            console.log(response.data);
            return response.data;
        }));
    }

    addTag(count: number, customer: string) {
        return this.http.post<any>(`${Hosts.API_HOST}/tag/generate`, { count, customer })
        .pipe(map(response => {
            console.log(response);
            return response;
        }));
    }

    activateTag(tagID: string) {
        return this.http.put<any>(`${Hosts.API_HOST}/tag/activate`, { tagID })
        .pipe(map(response => {
            console.log(response);
            return response;
        }));
    }
    
    editTagByOwner(tagID: number, item_name: string, name: string, phone: string, 
        email: string, address: string, permission: string, inbox: boolean, comment: string) {
        return this.http.put<any>(`${Hosts.API_HOST}/tag/edit/${tagID}`, 
            { tagID, item_name, user:{name}, phone, email,
                address, permission, comment, inbox })
        .pipe(map(response => {
            console.log(response);
            return response;
        }));
    }
    // editTagByAdmin(tagID: number, activation_code: string) {
    //     return this.http.put<any>(`${Hosts.API_HOST}/tag/disable/edit/${tagID}`, 
    //         { tagID, activation_code })
    //     .pipe(map(response => {
    //         console.log(response);
    //         return response;
    //     }));
    // }

    sendMsgTag(tagID: number, item_name: string, email: string, location: string, message: string, custom: boolean) {
        return this.http.post<any>(`${Hosts.API_HOST}/tag/send`, { tagID, item_name, email, location, message, custom })
        .pipe(map(response => {
            console.log(response);
            return response;
        }));
    }
}