import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

import { AuthenticationService } from '../../services';
import { User } from '../../../models';

@Component({
  selector: 'right-config',
  templateUrl: './right-config.component.html',
  styleUrls: ['./right-config.component.scss']
})
export class RightConfigComponent implements OnInit {
  avatarImgSrc: string = 'assets/images/avatar.png';

  currentUser: User;
  isAdmin: boolean;

  isConfigToggle: boolean = false;

  constructor(
    private _globalService: GlobalService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.parseUserData();
   }

  configToggle() {
    this.isConfigToggle = !this.isConfigToggle;
    //this._globalService._sidebarToggleState(!this.isConfigToggle);
    this._globalService.dataBusChanged('sidebarToggle', !this.isConfigToggle);
  }
 
  parseUserData() {
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.currentUser);
    if (this.currentUser && (this.currentUser.user.role['name'] === "admin")) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  onLogOut() {
    this.authenticationService.logout();
    location.reload();
  }
}
