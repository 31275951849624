import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../shared/services';

@Component({
  selector: 'app-signup',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signupForm: FormGroup
  loading = false;
  submitted = false;
  returnUrl: string;
  error: Array<any>;

  isPasswordVisible: boolean;
  isConfPasswordVisible: boolean;
  isCheckboxChecked: boolean;

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]],
      inbox: ['']
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = 'verify';
  }

  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  onSubmit() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.signupForm.invalid) {
        return;
    }

    this.loading = true;
    this.authenticationService.signUp(
        this.f.name.value, this.f.email.value, 
        this.f.password.value, this.f.confirm_password.value, 
        this.f.inbox.value
      )
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              localStorage.setItem("verifyEmail", this.f.email.value);
              this.router.navigate([this.returnUrl]);
            },
            error => {
                console.log(error);
                this.error = error;
                this.loading = false;
            });
  }

}
