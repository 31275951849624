import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../shared/services';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit {
  restorePasswordForm: FormGroup
  loading = false;
  submitted = false;
  returnUrl: string;
  error: Array<any>;

  isPasswordVisible: boolean;
  isConfPasswordVisible: boolean;

  currentRouteId: string;

  isRestored: boolean;
  
  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    console.log(this.route.params["value"].id);
    this.currentRouteId = this.route.params["value"].id;

    this.restorePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = 'verify';
  }

  // convenience getter for easy access to form fields
  get f() { return this.restorePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.restorePasswordForm.invalid) {
        return;
    }

    this.loading = true;

    this.authenticationService.restorePassword(this.currentRouteId, this.f.password.value, this.f.confirm_password.value)
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              this.isRestored =  true;
            },
            error => {
                console.log(error);
                this.error = error;
                this.loading = false;
                this.isRestored = false;
            });

    
  }

}
