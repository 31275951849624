import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Hosts, InboxMsg } from '../../models';

@Injectable({ providedIn: 'root' })
export class InboxService {
    constructor(private http: HttpClient) { }

    getAllMessages() {
        return this.http.get<InboxMsg[]>(`${Hosts.API_HOST}/message`);
    }
    
}