import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent} from './404/404.component';
import { VerifyComponent } from './verify/verify.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

   
import { AuthGuard } from '../_guards';
import { from } from 'rxjs';

export const childRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'tags/:id',
        component: HomeComponent,
    },
    {
        path: 'verify',
        component: VerifyComponent,
    },
    {
        path: 'verify/:id',
        component: VerifyComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
    },
    {
        path: 'restore-password/:id',
        component: RestorePasswordComponent,
    },
    {
        path: 'admin-panel',
        component: PagesComponent,
        children: [
            { path: '', redirectTo: 'tags', pathMatch: 'full' },
            { path: 'tags', loadChildren: './tags/tags.module#TagsModule', canActivate: [AuthGuard] },
            { path: 'users', loadChildren: './users/users.module#UsersModule', canActivate: [AuthGuard] },
            { path: 'users/:id', loadChildren: './users/users.module#UsersModule', canActivate: [AuthGuard] },           
            { path: 'admins', loadChildren: './admins/admins.module#AdminsModule', canActivate: [AuthGuard] },
            { path: 'profile', loadChildren: './profile/profile.module#ProfileModule', canActivate: [AuthGuard] },
            { path: 'change-password', loadChildren: './change-password/change-password.module#ChangePasswordModule', canActivate: [AuthGuard] },
            { path: 'inbox', loadChildren: './inbox/inbox.module#InboxModule', canActivate: [AuthGuard] },
            { path: 'inbox/:id', loadChildren: './inbox/inbox.module#InboxModule', canActivate: [AuthGuard] },           

            // { path: 'index', loadChildren: './index/index.module#IndexModule' },
            // { path: 'editor', loadChildren: './editor/editor.module#EditorModule' },
            // { path: 'icon', loadChildren: './icon/icon.module#IconModule' },
            // { path: 'form', loadChildren: './form/form.module#FormModule' },
            // { path: 'charts', loadChildren: './charts/charts.module#ChartsModule' },
            // { path: 'ui', loadChildren: './ui/ui.module#UIModule' },
            // { path: 'table', loadChildren: './table/table.module#TableModule' },
            // { path: 'menu-levels', loadChildren: './menu-levels/menu-levels.module#MenuLevelsModule' },
        ]
    },
    { path: '**', component: NotFoundComponent }
];

export const routing = RouterModule.forChild(childRoutes);
