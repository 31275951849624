﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { map } from 'rxjs/operators';

import { Hosts, User } from '../../models';

@Injectable({ providedIn: 'root' })
export class OwnersService {
    constructor(private http: HttpClient) { }

    getAllOwners() {
        return this.http.get<User[]>(`${Hosts.API_HOST}/user`);
    }
}