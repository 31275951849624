import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { TagsService, AuthenticationService } from '../../shared/services';
import { Tag, User } from '../../models';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sendMsgForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  currentTag: Tag;

  currentRouteId: string;
  currentLocation: string;

  public lat;
  public lng;

  currentUser: User;
  isAdmin: boolean;

  qrScan: boolean;
  dataLoaded: boolean;
  custom: boolean = false;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private tagsService: TagsService,
      private authenticationService: AuthenticationService
  ) {
    this.initSendMsgForm();

   }

  ngOnInit() {
    this.currentTag = new Tag();
    // console.log(this.route.params["value"].id);
    this.currentRouteId = this.route.params["value"].id;
    // if (this.currentRouteId) {
    this.viewTag(this.currentRouteId);
      // this.getLocation();
    // }
    
    this.parseUserData();
    if (this.currentUser) {
      this.qrScan = false;
      // this.authenticationService.logout();
      // location.reload();
    } else {
      this.qrScan = true;
    }


  }

  /**** parse current user data: *****/
  parseUserData() {
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.currentUser);
    if (this.currentUser && (this.currentUser.user.role['name'] === "admin")) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  /*** viewTag: ***/
  viewTag(id) {
    if (id)
      this.tagsService.getTag(id)
          .pipe(first())
          .subscribe(
              data => {
                console.log(data);
                this.currentTag = data;
                this.dataLoaded = true;
                if (this.currentTag.status)
                  this.getLocation();
                else {
                  localStorage.setItem("activateTagID", String(this.currentTag.tagID));
                }
              },
              error => {
                console.log(error);
                this.error = error;
                this.dataLoaded = true;
              });
    else 
      this.dataLoaded = true;
  }

  /**** init sendMsgForm: *****/
  initSendMsgForm() {
    this.sendMsgForm = this.formBuilder.group({
      message: ["I found your tag. You can email me or call me at ...", Validators.maxLength(200)]
    });
  }

  /**** sendMsg method for hidden msg send and on form submit: *****/
  sendMsg() {
    this.tagsService.sendMsgTag(
      this.currentTag.tagID, 
      this.currentTag.item_name, 
      this.currentTag.email, 
      this.currentLocation, 
      this.f.message.value,
      this.custom
    )
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              if (this.qrScan && this.submitted)
                alert("Thank you for the message.")
            },
            error => {
                this.error = error;
                this.loading = false;
            });
  }

  // convenience getter for easy access to form fields
  get f() { return this.sendMsgForm.controls; }

  /**** submit sendMsgForm: ****/
  onSubmit() {
    this.submitted = true;
    console.log(this.f);
    // stop here if form is invalid
    if (this.sendMsgForm.invalid) {
        return;
    }

    this.loading = true;
    // for sendMsg from the feedback form:
    this.custom = true; 

    this.sendMsg();
  }

  /**** get user location: ****/
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          console.log(this.lat);
          console.log(this.lng);
          this.currentLocation = `${this.lat},${this.lng}`;
          console.log(this.currentLocation);
          // this.initSendMsgForm();
          if (this.qrScan) {
            console.log(this.qrScan);
            // for sendMsg via qr scan:
            this.custom = false; 
            this.sendMsg();
          } 
        } 
      } ,
        (error: PositionError) => {
          this.currentLocation="";
          console.log(this.currentLocation);
          // this.initSendMsgForm();
          if (this.qrScan) {
            // for sendMsg via qr scan:
            this.custom = false; 
            this.sendMsg();
          } 
          console.log(error);
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    } 
  }
}

