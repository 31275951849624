import { UserData } from "./UserData";

export class User {
    token?: string;
    user: UserData;
    // {
    //     id: number;
    //     name: string;
    //     email: string;
    //     role: Object;
    //     role_id: number;
    //     address: string;
    //     phone: string;
    // }
}