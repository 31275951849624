﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Hosts, User } from '../../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${Hosts.API_HOST}/auth/login`, { email, password })
            .pipe(map(data => {
                // login successful if there's a jwt token in the response
                if (data && data.data.token) {
                    console.log(data);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(data.data));
                    this.currentUserSubject.next(data.data);
                }

                return data;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    parseToken() {
        return this.http.get<any>(`${Hosts.API_HOST}/auth/parseToken`)
            .pipe(map(data => {
                console.log(data.data);

                return data.data;
            }));
    }

    forgotPassword(email: string) {
        return this.http.post<any>(`${Hosts.API_HOST}/auth/forgotPassword`, { email })
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }

    restorePassword(token: string, newPassword: string, confirm_newPassword: string) {
        return this.http.put<any>(`${Hosts.API_HOST}/auth/restorePassword/${token}`, { newPassword, confirm_newPassword})
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }

    signUp(name: string, email: string, password: string, confirm_password: string, inbox: boolean) {
        return this.http.post<any>(`${Hosts.API_HOST}/auth/register`, { name, email, password, confirm_password, inbox })
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }

    verify(token: string) {
        return this.http.put<any>(`${Hosts.API_HOST}/user/verify/${token}`, {})
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }
    
    resendVerify(email: string) {
        return this.http.post<any>(`${Hosts.API_HOST}/user/verify/resend`, { email })
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }

    getUser(id: number) {
        return this.http.get<any>(`${Hosts.API_HOST}/user/${id}`)
        .pipe(map(data => {
                let currentUser = this.currentUserValue;
                currentUser.user = data.data;
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                this.currentUserSubject.next(currentUser);
            return data;
        }));
    }
    
    editUser(name: string, phone: string, email: string, address: string) {
        return this.http.put<any>(`${Hosts.API_HOST}/user/edit`, { name, phone, email, address })
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }

    changeUserPassword(token: string, oldPassword: string, newPassword: string, confirm_newPassword: string) {
        return this.http.put<any>(`${Hosts.API_HOST}/user/changePassword/${token}`, { oldPassword, newPassword, confirm_newPassword })
            .pipe(map(data => {
                console.log(data);

                return data;
            }));
    }
}