export class Tag {
    id: number;
    tagID: number;
    address: string;
    comment: string;
    customer: string;
    email: string;
    permission: string;
    phone: string;
    status: boolean;
    item_name: string;
    inbox: boolean;
    user: Object;
    inbox_count: number;
    user_id: number;
    // activation_code: string;
    // user: Object; //name
}